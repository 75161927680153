
/*--- Colores ----*/
body {
  margin: 0;
  background-color: black;
  font-family: 'Poppins', sans-serif!important;
}

p {
  font-weight: 200;
}

h1, h2, h3, h4, h5, span, p, a{
  color: #ffffff;
}

.card-title {
  color: #ffffff;
}

body button.btn{
  animation: changing-border-only 2s linear infinite;
  border: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0);
  color: #ffffff;
}

body button:hover{
  animation: changing-border 2s linear infinite; 
}




/*--- Header ---*/
.bg-dark {
  background-color: #000000!important;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 30px;
  padding-left: 30px;
  white-space: nowrap;
}



/*--- Botones btn ---*/
body button.btn {
  width: 80%;
  max-width: 200px;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
}





/*--- Home ---*/
.card-body {
  min-height: 200px;
}



/*--- Footer ---*/
.footer {
  background-color: #000000;
  color: #fff;
}

.footer {
  background-color: #000000;
  color: #fff;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  font-size: 12px;
  width: 100%;
}

.footer a{
  color: #ffffff;
}

.footer-right #contact-button:hover{
  background-color: #000000!important;
}

.footer-right #contact-button:hover a{
  color: #ffffff!important;
}



/*--- Preguntas frecuentes ---*/
.accordion-faq, .faqintro, .faqfooter{
  margin: 5% 0;
}

.accordion-item, .accordion{
  color: #fff;
  background-color: #000000;
  --bs-accordion-color: #fff;
  --bs-accordion-bg: #000000;
}

.accordion-button:not(.collapsed){
  animation: changing-border 2s linear infinite; 
}

.accordion-button{
  color: #fff;
}

.accordion-button:not(.collapsed){
  color: #000000;
}

.accordion h2{
  margin: 3% 0 1% 0;
}

h2.accordion-header{
  margin: 0!important;
  border-top: 1px solid #cacaca !important;
}







/*--- Tarjeta suscriptions---*/
.card{
  background-color: #000000;
  animation: changing-border-only 2s linear infinite;
}

.titulo{
  margin: 3% 0;
}

img.suscr-img{
  width: 30px;
}




/*--- Contact form ---*/
.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.contact-text {
  width: 50%;
  padding-right: 20px;
}

.contact-form {
  width: 50%;
}

.contact-form .form-row {
  display: flex;
  justify-content: space-between;
}

.contact-form .form-group {
  flex: 0 0 48%;
  margin-bottom: 15px;
}

.contact-form label {
  color: white;
}

.contact-form .btn {
  width: 100%;
  border: none;
  padding: 10px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  animation: changing-border-only 2s linear infinite;
  border: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0);
  color: #ffffff;
  margin: 0;
}

.contact-form .btn:hover{
  animation: changing-border 2s linear infinite; 
}

.contact-form .btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}







@media (max-width: 768px) {
  .accordion h2 {
    margin: 10% 0 4% 0;
  }
}




@keyframes changing-border-only {
  0% { border-color: #FF6347; } /* Tomato */
  25% { border-color: #4682B4; } /* SteelBlue */
  50% { border-color: #32CD32; } /* LimeGreen */
  75% { border-color: #FFD700; } /* Gold */
  100% { border-color: #FF6347; } /* Back to Tomato */
}

@keyframes changing-border {
  0% { border-color: #FF6347; background-color: #FF6347; } /* Tomato */
  25% { border-color: #4682B4; background-color: #4682B4; } /* SteelBlue */
  50% { border-color: #32CD32; background-color: #32CD32; } /* LimeGreen */
  75% { border-color: #FFD700; background-color: #FFD700; } /* Gold */
  100% { border-color: #FF6347; background-color: #FF6347; } /* Back to Tomato */
}